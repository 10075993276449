import React from 'react';

export default function MaintenanceSvg() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#232;&#183;&#175;&#229;&#134;&#181;&#228;&#186;&#139;&#228;&#187;&#182;-traffic-events">
        <g id="Subtract">
          <path d="M29.5237 17L24 3.82812L18.4763 17H29.5237Z" fill="var(--td-text-color-placeholder)" />
          <path d="M31.2011 21H16.7989L13.6699 28.4615H34.3301L31.2011 21Z" fill="var(--td-text-color-placeholder)" />
          <path
            d="M36.0076 32.4615H11.9924L9.66997 37.9997H6V41.9997H42V37.9997H38.33L36.0076 32.4615Z"
            fill="var(--td-text-color-placeholder)"
          />
        </g>
      </g>
    </svg>
  );
}
