import React from 'react';

export default function EmptySvg() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#230;&#151;&#160;&#231;&#187;&#147;&#230;&#158;&#156;-no-result">
        <g id="Union">
          <path d="M22 0H26V8H22V0Z" fill="var(--td-text-color-placeholder)" />
          <path
            d="M10.002 1.17157L7.17353 4L13.002 9.82843L15.8304 7L10.002 1.17157Z"
            fill="var(--td-text-color-placeholder)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 27.4689L10.8394 12H37.1606L46 27.4689V44H2V27.4689ZM13.1606 16L7.44636 26H17.8025L18.1889 27.5015C18.8551 30.0898 21.207 32 24 32C26.793 32 29.1449 30.0898 29.8111 27.5015L30.1975 26H40.5536L34.8394 16H13.1606Z"
            fill="var(--td-text-color-placeholder)"
          />
          <path
            d="M37.998 1.17157L32.1696 7L34.998 9.82843L40.8265 4L37.998 1.17157Z"
            fill="var(--td-text-color-placeholder)"
          />
        </g>
      </g>
    </svg>
  );
}
