import React from 'react';

export default function NetworkErrorSvg() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#230;&#151;&#160;&#231;&#189;&#145;&#231;&#187;&#156;-no-network">
        <g id="Union">
          <path d="M26 17V2H22V17H26Z" fill="var(--td-text-color-placeholder)" />
          <path d="M26.0078 20H22V24.0078H26.0078V20Z" fill="var(--td-text-color-placeholder)" />
          <path
            d="M2.75751 13.45C7.29713 9.80916 12.553 7.50276 18 6.53088V28H30V6.53052C35.4475 7.50216 40.7038 9.80854 45.2438 13.4497L46.8021 14.6995L24.0006 43.2016L1.19922 14.6998L2.75751 13.45Z"
            fill="var(--td-text-color-placeholder)"
          />
        </g>
      </g>
    </svg>
  );
}
