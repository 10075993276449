/**
 * 该文件为脚本自动生成文件，请勿随意修改。如需修改请联系 PMC
 * */

import { TdStatisticProps } from './type';

export const statisticDefaultProps: TdStatisticProps = {
  animationStart: false,
  loading: false,
  separator: ',',
  trendPlacement: 'left',
};
